import { jsx as _jsx } from "react/jsx-runtime";
import _ from 'lodash';
import { getSelectValue } from '../../helpers';
import { ReactSelect } from './inputs';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function DictionarySelect(_a) {
    var values = _a.values, onChange = _a.onChange, value = _a.value, errors = _a.errors, label = _a.label, name = _a.name, selectedValue = _a.selectedValue, emptyValue = _a.emptyValue;
    var options = emptyValue
        ? _.concat([{ value: '', label: emptyValue }], values)
        : values;
    var defaultValue = getSelectValue(value, options);
    return (_jsx(ReactSelect, { onChange: onChange, defaultValue: defaultValue, errors: errors, values: values, label: label, selectedValue: selectedValue, name: name, isCreatable: true, toUpperCase: true }));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default DictionarySelect;
