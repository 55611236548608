var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
/* eslint-disable no-unused-vars */
import _ from 'lodash';
import { useEffect, useState } from 'react';
import BTable from 'react-bootstrap/Table';
import { FaDotCircle, FaAngleRight, FaAngleDown, FaUsers, FaArrowsAltV, FaLongArrowAltUp, FaLongArrowAltDown } from 'react-icons/fa';
import { useTable, usePagination, useSortBy, useGroupBy, useExpanded } from 'react-table';
import Pagination from '../components/common/Pagination';
import { Button, Form } from './common/bootstrap';
import Spinner from './common/Spinner';
/* eslint-disable no-nested-ternary */
/* eslint-disable lines-around-comment */
/* eslint-disable max-len */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Table(_a) {
    var columns = _a.columns, data = _a.data, loading = _a.loading, _b = _a.colored, colored = _b === void 0 ? false : _b, _c = _a.colorClassName, colorClassName = _c === void 0 ? '' : _c, mb = _a.mb, _d = _a.withHiddenColumns, withHiddenColumns = _d === void 0 ? false : _d, _e = _a.withGroup, withGroup = _e === void 0 ? false : _e, _f = _a.withSort, withSort = _f === void 0 ? true : _f, _g = _a.withoutPaging, withoutPaging = _g === void 0 ? false : _g, handleSelectRow = _a.handleSelectRow, _h = _a.groupByFields, groupByFields = _h === void 0 ? [''] : _h, _j = _a.withFooter, withFooter = _j === void 0 ? false : _j, handleDoubleClick = _a.handleDoubleClick;
    var _k = __read(useState(null), 2), selectedRow = _k[0], setSelectedRow = _k[1];
    var _l = __read(useState(''), 2), hiddenColumnId = _l[0], setHiddenColumnId = _l[1];
    var _m = useTable({
        columns: columns,
        data: data,
        initialState: {
            pageIndex: 0,
            pageSize: withoutPaging ? 10000 : 50,
            groupBy: groupByFields
        }
    }, useGroupBy, useSortBy, useExpanded, usePagination), getTableProps = _m.getTableProps, getTableBodyProps = _m.getTableBodyProps, headerGroups = _m.headerGroups, footerGroups = _m.footerGroups, prepareRow = _m.prepareRow, page = _m.page, canPreviousPage = _m.canPreviousPage, canNextPage = _m.canNextPage, pageCount = _m.pageCount, allColumns = _m.allColumns, toggleAllRowsExpanded = _m.toggleAllRowsExpanded, toggleGroupBy = _m.toggleGroupBy, gotoPage = _m.gotoPage, nextPage = _m.nextPage, previousPage = _m.previousPage, setPageSize = _m.setPageSize, _o = _m.state, pageIndex = _o.pageIndex, pageSize = _o.pageSize, hiddenColumns = _o.hiddenColumns;
    var onSelectRow = function (row) {
        if (row && handleSelectRow) {
            setSelectedRow(row);
            handleSelectRow(row);
        }
    };
    var handleToggleHidden = function (column) {
        toggleAllRowsExpanded(false);
        setHiddenColumnId(column === null || column === void 0 ? void 0 : column.id);
    };
    var getColumnName = function (column) {
        return _.get(column, 'Header') || (column === null || column === void 0 ? void 0 : column.id);
    };
    // убираем раскрытие последней колонки т.к если сгруппировать все колонки и раскрыть до последнего,
    // у последнего будут пустые строки т.к больше колонок нет по которому нужно группировать
    var getLastElementIndex = function () {
        var _a, _b, _c;
        // количество колонок берем отсюда, а не из allColumns потому что при скрытии колонок актуальные будут только тут
        var lastCell = _.last((_a = page[0]) === null || _a === void 0 ? void 0 : _a.cells);
        var isCountColumn = lastCell ? lastCell.column.id == 'totalCount' : false;
        var visibleCellsLength = (_c = (_b = page[0]) === null || _b === void 0 ? void 0 : _b.cells) === null || _c === void 0 ? void 0 : _c.length;
        if (_.isNumber(visibleCellsLength)) {
            if (isCountColumn) {
                return visibleCellsLength - 2;
            }
            return visibleCellsLength - 1;
        }
        return null;
    };
    var onDoubleClick = function (row) {
        if (row && handleDoubleClick) {
            handleDoubleClick(row);
        }
    };
    // если тоглить во время нажатия на скрытие колонки, работает криво. Потому что формируется массив группировки и
    // колонка добавляется в конец массива из-за чего и криво работает.
    // поэтому тоглить нужно после того как колонка появится. В таком случае колонка в массив группировки попадет
    // в нужную позицию, а не в конец
    useEffect(function () {
        if (hiddenColumnId) {
            toggleGroupBy(hiddenColumnId);
        }
    }, [hiddenColumns]);
    return (_jsxs(_Fragment, { children: [withHiddenColumns && _jsx("div", __assign({ className: 'table__hidden-wrapper' }, { children: allColumns.map(function (column) { return (_jsx(Button, __assign({ className: 'table__hidden-button', variant: 'light', onClick: function () { return handleToggleHidden(column); } }, { children: _jsx(Form.Check, __assign({ label: getColumnName(column) || '', id: column.id, className: 'pl-0 mb-0', type: 'checkbox' }, column.getToggleHiddenProps())) }), column.id)); }) })), _jsx("div", __assign({ className: "table-wrapper ".concat(mb ? "mb-".concat(mb) : '') }, { children: _jsxs(BTable, __assign({ striped: true, bordered: true, hover: true, size: 'sm' }, getTableProps(), { children: [_jsx("thead", { children: headerGroups.map(function (headerGroup, i) { return (_createElement("tr", __assign({}, headerGroup.getHeaderGroupProps(), { key: i }), headerGroup.headers.map(function (column, i) { return (_createElement("th", __assign({}, column.getHeaderProps(), { key: i, className: 'text-nowrap' }),
                                column.canGroupBy && withGroup ? (_jsx("span", __assign({}, column.getGroupByToggleProps(), { className: 'mr-1' }, { children: column.isGrouped ? _jsx(FaDotCircle, {}) : _jsx(FaUsers, {}) }))) : null,
                                column.render('Header'),
                                _jsx("span", __assign({}, column.getSortByToggleProps(), { className: 'mr-1' }, { children: column.isSorted && withSort
                                        ? column.isSortedDesc
                                            ? _jsx(FaLongArrowAltDown, {})
                                            : _jsx(FaLongArrowAltUp, {})
                                        : column.disableSortBy ? '' : _jsx(FaArrowsAltV, {}) })))); }))); }) }), _jsxs("tbody", __assign({}, getTableBodyProps(), { children: [loading && _.isEmpty(page) &&
                                    _jsx("tr", { children: _jsx("td", __assign({ colSpan: headerGroups[0].headers.length }, { children: _jsx(Spinner, {}) })) }), !loading && !_.isEmpty(page) && page.map(function (row, i) {
                                    prepareRow(row);
                                    return (_createElement("tr", __assign({}, row.getRowProps(), { key: i, onClick: function () { return onSelectRow(row); }, className: colored ? colorClassName : '', onDoubleClick: function () { return onDoubleClick(row); }, style: __assign({}, (row === null || row === void 0 ? void 0 : row.index) == _.get(selectedRow, 'index') && handleSelectRow &&
                                            { backgroundColor: '#d1d3e2' }) }), row.cells.map(function (cell, i) { return _createElement("td", __assign({}, cell.getCellProps(), { key: i, className: "text-nowrap ".concat(_.isNumber(cell.value) ? 'align-right' : '') }), cell.isGrouped && withGroup && row.depth != getLastElementIndex() ? (
                                    // If it's a grouped cell, add an expander and row count
                                    _jsxs("div", __assign({}, row.getToggleRowExpandedProps(), { children: [_jsx("span", __assign({ className: 'mr-1' }, { children: row.isExpanded ? _jsx(FaAngleDown, {}) : _jsx(FaAngleRight, {}) })), ' ', cell.render('Cell')] }))) : cell.isAggregated ? (
                                    // If the cell is aggregated, use the Aggregated
                                    // renderer for cell
                                    cell.render('Aggregated')) : cell.isPlaceholder ? null : ( // For cells with repeated values, render null
                                    // Otherwise, just render the regular cell
                                    cell.render('Cell'))); })));
                                }), !loading && _.isEmpty(page) &&
                                    _jsx("tr", { children: _jsx("td", __assign({ colSpan: headerGroups[0].headers.length }, { children: "\u041D\u0438\u0447\u0435\u0433\u043E \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E" })) })] })), withFooter && _jsx("tfoot", { children: footerGroups.map(function (group, index) { return (_createElement("tr", __assign({}, group.getFooterGroupProps(), { key: index }), group.headers.map(function (column) { return (_createElement("td", __assign({}, column.getFooterProps(), { key: column.id }), column.render('Footer'))); }))); }) })] })) })), !withoutPaging && !withGroup && (data === null || data === void 0 ? void 0 : data.length) != 0 &&
                _jsx("div", __assign({ className: 'mb-2' }, { children: "\u041F\u043E\u043A\u0430\u0437\u0430\u043D\u043E ".concat(pageIndex * pageSize + 1, " - ").concat(pageIndex * pageSize + page.length, " \u0438\u0437 ").concat(data.length) })), !withoutPaging && _jsx(Pagination, { canPreviousPage: canPreviousPage, canNextPage: canNextPage, pageCount: pageCount, gotoPage: gotoPage, nextPage: nextPage, previousPage: previousPage, setPageSize: setPageSize, pageIndex: pageIndex, pageSize: pageSize })] }));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Table;
