var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Outlet } from 'react-router-dom';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function MainLayout() {
    return (_jsx("div", __assign({ id: 'wrapper' }, { children: _jsx("div", __assign({ id: 'content-wrapper', className: 'd-flex flex-column' }, { children: _jsx("div", __assign({ id: 'content' }, { children: _jsx("div", __assign({ className: 'container-fluid' }, { children: _jsx(Outlet, {}) })) })) })) })));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default MainLayout;
