var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import MainLayout from '../components/MainLayout';
import NotFoundPage from '../pages/404/NotFoundPage';
import Cards from '../pages/Cards';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function AppRoutes() {
    return (_jsxs(Routes, { children: [_jsxs(Route, __assign({ path: '/', element: _jsx(MainLayout, {}) }, { children: [_jsx(Route, { index: true, element: _jsx(Home, {}) }), _jsx(Route, { path: '/cards', element: _jsx(Cards, {}) })] })), _jsx(Route, { path: '*', element: _jsx(NotFoundPage, {}) })] }));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default AppRoutes;
