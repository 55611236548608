var _a;
import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { autoScannerAPI } from '../api';
import page from './slices/pageSlice';
import data from './slices/dataSlice';
import { CLEAR_STORE } from './actions/constants';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var appReducer = combineReducers((_a = {},
    _a[autoScannerAPI.reducerPath] = autoScannerAPI.reducer,
    _a.page = page,
    _a.data = data,
    _a));
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var rootReducer = function (state, action) {
    if (action.type === CLEAR_STORE) {
        state = undefined;
    }
    return appReducer(state, action);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var setupStore = function () { return configureStore({
    reducer: rootReducer,
    middleware: function (getDefaultMiddleware) {
        return getDefaultMiddleware({
            serializableCheck: false
        }).concat(autoScannerAPI.middleware);
    }
}); };
