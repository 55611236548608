var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { Link } from 'react-router-dom';
import { Row, Col } from '../../components/common/bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default function NotFoundPage() {
    return (_jsx(_Fragment, { children: _jsx(Row, __assign({ className: 'text-center mt-5 font-weight-bold w-100' }, { children: _jsxs(Col, { children: [_jsx("div", { children: "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u0430" }), _jsxs("div", { children: ["\u0412\u0435\u0440\u043D\u0443\u0442\u0441\u044F \u043D\u0430", _jsx(Link, __assign({ to: '/', className: 'ml-2' }, { children: "\u0433\u043B\u0430\u0432\u043D\u0443\u044E" }))] })] }) })) }));
}
