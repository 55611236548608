var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import _ from 'lodash';
import { FaAngleDown, FaAngleRight } from 'react-icons/fa';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
var Panel = function (_a) {
    var children = _a.children, header = _a.header, collapsible = _a.collapsible, _b = _a.defaultCollapse, defaultCollapse = _b === void 0 ? false : _b, collapsedText = _a.collapsedText, borderTopNone = _a.borderTopNone;
    var _c = __read(useState(defaultCollapse), 2), show = _c[0], setShow = _c[1];
    var headerClassNames = 'card-header py-3 d-flex align-items-center';
    return (_jsxs("div", __assign({ className: 'card shadow mb-4' }, { children: [header &&
                _jsxs("div", __assign({ 
                    // eslint-disable-next-line max-len
                    className: "".concat(headerClassNames, " ").concat(!show ? 'collapsed' : '', " ").concat(borderTopNone ? 'border-top-none' : ''), role: collapsible ? 'button' : 'none', onClick: collapsible ? function () { return setShow(!show); } : _.noop }, { children: [collapsible &&
                            _jsx("div", __assign({ className: 'mr-2' }, { children: show ? _jsx(FaAngleDown, {}) : _jsx(FaAngleRight, {}) })), _jsx("h6", __assign({ className: 'm-0 font-weight-bold text-primary' }, { children: header }))] })), _jsx("div", __assign({ className: collapsible ? "collapse ".concat(show ? 'show' : '') : '' }, { children: _jsx("div", __assign({ className: 'card-body' }, { children: children })) })), collapsedText && collapsible && !show && _jsx("div", __assign({ className: 'card-body' }, { children: collapsedText }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Panel;
