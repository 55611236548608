import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    title: ''
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var pageSlice = createSlice({
    name: 'page',
    initialState: initialState,
    reducers: {
        setPageTitle: function (state, action) {
            state.title = action.payload;
        }
    }
});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var setPageTitle = pageSlice.actions.setPageTitle;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default pageSlice.reducer;
