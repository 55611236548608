export var categories = [
    { value: 'L', label: 'L' },
    { value: 'M1', label: 'M1 (Легковые автомобили для перевозки не более 8 пассажиров)' },
    { value: 'M2', label: 'M2 (Более 8 пасс. мест, макс. масса менее 5 т.)' },
    { value: 'M3', label: 'M3 (Более 8 пасс. мест, макс. масса более 5 т.)' },
    { value: 'N1', label: 'N1 (грузовые автомобили до 3,5 т, фургоны)' },
    { value: 'N2', label: 'N2 (Макс. масса свыше 3,5 т, но не более 12 т.)' },
    { value: 'N3', label: 'N3 (Макс. масса более 12 т.)' },
    { value: 'O1', label: 'O1 (Макс. масса, не более 0,75 т.)' },
    { value: 'O2', label: 'O2 (Макс. масса свыше 0,75 т, но не более 3,5 т.)' },
    { value: 'O3', label: 'O3 (Макс. масса свыше 3,5 т, но не более 10 т.)' },
    { value: 'O4', label: 'O4 (Макс. масса более 10 т.)' },
    { value: 'Tb', label: 'Tb (Троллейбус)' },
    { value: 'Tm', label: 'Tm (Трамвай)' }
];
