import { useEffect } from 'react';
/* eslint-disable no-unused-vars */
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function useOutsideClick(ref, setShow) {
    useEffect(function () {
        function handleClickOutside(event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setShow(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return function () {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default useOutsideClick;
