import { createSlice } from '@reduxjs/toolkit';
var initialState = {
    cards: []
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dataSlice = createSlice({
    name: 'data',
    initialState: initialState,
    reducers: {
        setCardsData: function (state, action) {
            state.cards = action.payload;
        }
    }
});
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var setCardsData = dataSlice.actions.setCardsData;
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default dataSlice.reducer;
