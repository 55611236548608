/* eslint-disable func-names */
/* eslint-disable object-shorthand */
import * as yup from 'yup';
// После добавления метода необходимо его задекларировать в файле ./@types/yup.d.ts
// yup.addMethod(yup.string, 'checkClientType', function(type: string, msg?: string) {
//     const ref = yup.ref('clientType');
//     return this.test({
//         name: 'checkClientType',
//         exclusive: false,
//         message: msg || 'not valid', // Сообщение об ошибке
//         params: {reference: ref ? ref.path : undefined},
//         test: function(value) {
//             const clientType = this.resolve(ref);
//             if(clientType && clientType == type && (value == null || value == '')) {
//                 return false;
//             }
//             return true;
//         }
//     });
// });
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default yup;
