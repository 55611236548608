import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/dist/query/react';
import config from './config';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var autoScannerAPI = createApi({
    reducerPath: 'autoScannerAPI',
    baseQuery: fetchBaseQuery({ baseUrl: config.apiUrl }),
    tagTypes: ['Cards'],
    endpoints: function (build) { return ({
        getCards: build.mutation({
            query: function (body) { return ({
                method: 'POST',
                url: '/api/public/card',
                body: body
            }); },
            transformResponse: function (response) {
                return response;
            }
        })
    }); }
});
