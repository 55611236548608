var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import download from 'downloadjs';
import { FaAngleLeft } from 'react-icons/fa';
import Table from '../../components/Table';
import { Row, Col, Panel } from '../../components/common/bootstrap';
import PhotoCard from '../../components/common/PhotoCard';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import config from '../../api/config';
import { autoScannerAPI } from '../../api';
import { toServerDateOnly } from '../../helpers';
import { setCardsData } from '../../redux/slices';
import { useUrl } from '../../hooks';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Cards() {
    var dispatch = useAppDispatch();
    var _a = __read(useState({ frontId: '', backId: '' }), 2), activePhotoUrl = _a[0], setActivePhotoUrl = _a[1];
    var _b = __read(autoScannerAPI.useGetCardsMutation(), 2), getCards = _b[0], _c = _b[1], data = _c.data, isLoading = _c.isLoading;
    var cards = useAppSelector(function (state) { return state.data.cards; });
    var urlParams = useUrl().urlParams;
    var from = urlParams.from, to = urlParams.to, make = urlParams.make, model = urlParams.model, registerSign = urlParams.registerSign, vinNumber = urlParams.vinNumber, chassisNumber = urlParams.chassisNumber, bodyNumber = urlParams.bodyNumber, subCategories = urlParams.subCategories, year = urlParams.year;
    var handleSelectRow = function (row) {
        var _a, _b;
        var frontId = (_a = row === null || row === void 0 ? void 0 : row.original) === null || _a === void 0 ? void 0 : _a.photoBeforeId;
        var backId = (_b = row === null || row === void 0 ? void 0 : row.original) === null || _b === void 0 ? void 0 : _b.photoAfterId;
        if (frontId && backId) {
            setActivePhotoUrl({
                frontId: frontId,
                backId: backId
            });
        }
    };
    var handleDownload = function (url) {
        if (url) {
            download(url);
        }
    };
    var columns = [
        {
            Header: 'ГРЗ',
            accessor: 'registerSign'
        },
        {
            Header: 'VIN номер',
            accessor: 'vin'
        },
        {
            Header: 'Номер шасси',
            accessor: 'chassisNumber'
        },
        {
            Header: 'Номер кузова',
            accessor: 'bodyNumber'
        },
        {
            Header: 'Дата',
            accessor: 'signedDate'
        },
        {
            Header: 'Марка',
            accessor: 'make'
        },
        {
            Header: 'Модель',
            accessor: 'model'
        },
        {
            Header: 'Год выпуска',
            accessor: 'year'
        },
        {
            Header: 'Категория',
            accessor: 'subCategory'
        }
    ];
    useEffect(function () {
        var hasFilter = make || model || registerSign || vinNumber || chassisNumber
            || bodyNumber || !_.isEmpty(subCategories) || year;
        if (_.isEmpty(cards) && from && to && hasFilter) {
            var params = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, vinNumber && { vinNumber: vinNumber }), make && { make: make }), model && { model: model }), registerSign && { registerSign: registerSign }), chassisNumber && { chassisNumber: chassisNumber }), bodyNumber && { bodyNumber: bodyNumber }), subCategories && { subCategories: subCategories }), year && { year: year });
            getCards(__assign({ from: toServerDateOnly(from), to: toServerDateOnly(to) }, params));
        }
    }, []);
    useEffect(function () {
        if (data) {
            dispatch(setCardsData(data));
        }
    }, [data]);
    return (_jsx("div", __assign({ className: 'pt-4' }, { children: _jsx(Panel, __assign({ header: '\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u043F\u043E\u0438\u0441\u043A\u0430' }, { children: _jsxs(Row, { children: [_jsx(Col, __assign({ md: 12 }, { children: _jsxs(Link, __assign({ className: 'btn btn-primary mb-4 back-button', to: '/' }, { children: [_jsx(FaAngleLeft, {}), _jsx("span", { children: "\u041D\u0430\u0437\u0430\u0434" })] })) })), _jsx(Col, __assign({ md: 8 }, { children: _jsx(Table, { data: cards, columns: columns, loading: isLoading, handleSelectRow: handleSelectRow }) })), !_.isEmpty(cards) && _jsx(Col, __assign({ md: 4 }, { children: _jsxs("div", __assign({ className: 'preview-wrapper' }, { children: [_jsx(Preview, { handleDownload: handleDownload, id: activePhotoUrl.frontId, title: '\u0421\u043F\u0435\u0440\u0435\u0434\u0438' }), _jsx(Preview, { handleDownload: handleDownload, id: activePhotoUrl.backId, title: '\u0421\u0437\u0430\u0434\u0438' })] })) }))] }) })) })));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Preview(_a) {
    var id = _a.id, title = _a.title, handleDownload = _a.handleDownload;
    var url = "".concat(config.apiUrl, "/api/public/photo/").concat(id);
    var buttons = [
        {
            title: 'Скачать',
            icon: 'download',
            onClick: handleDownload,
            disabled: false
        }
    ];
    return (_jsx("div", { children: id
            ? _jsx(PhotoCard, { className: 'mb-4', title: title, url: url, buttons: buttons })
            : _jsx(Panel, { children: "\u041A\u043B\u0438\u043A\u043D\u0438\u0442\u0435 \u043F\u043E \u0441\u0442\u0440\u043E\u043A\u0435" }) }));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Cards;
