// eslint-disable-next-line import/named
import _ from 'lodash';
import moment from 'moment';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dateFormat = 'DD.MM.YYYY';
export var dateTimeFormat = 'DD.MM.YYYY HH:mm';
export var serverDateTimeFormat = 'YYYY-MM-DDTHH:mm:ss.SSS';
export var serverTimeFormat = 'HH:mm:ss.SSS';
export var serverDateOnlyFormat = 'YYYY-MM-DD';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var monthRusMap = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь',
    'октябрь', 'ноябрь', 'декабрь'];
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var formatDate = function (time) {
    if (!time) {
        return '';
    }
    return moment(time).format(dateFormat);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var formatDateTime = function (time) {
    if (!time) {
        return '';
    }
    return moment(time).format(dateTimeFormat);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var toServerDateOnly = function (date) {
    return moment(date, dateFormat).format(serverDateOnlyFormat);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dateToISO = function (date) {
    return moment(date, dateFormat).format('YYYY-MM-DD');
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var dateToISOEndTime = function (date) {
    return moment(date, dateFormat).format('YYYY-MM-DD');
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var formatServerDateTime = function (date) {
    return moment(date, serverDateTimeFormat).format(dateFormat);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var currentMonth = function () {
    return formatDate(moment().set({ date: 1 }));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var currentDatePlusDays = function (days) {
    return formatDate(moment().add(days, 'days'));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var nextMonth = function () {
    return formatDate(moment().add(1, 'months').set({ date: 1 }));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var prevMonth = function () {
    return formatDate(moment().add(-1, 'months').set({ date: 1 }));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var hasOnlyDigits = function (value) {
    return _.every(value, function (item) { return '0123456789'.indexOf(item) != -1; });
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var hasOnlyCyrillic = function (value) {
    return _.every(value, function (item) { return 'АБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя'.indexOf(item) != -1; });
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var hasOnlyLatinOrDigits = function (value) {
    return _.every(value, function (item) { return '01234567890ABCDEFGHJKLMNPRSTUVWXYZabcdefghjklmnprstuvwxyz'.indexOf(item) != -1; });
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var hasOnlyLatinOrDigitsOrUnderscore = function (value) {
    return _.every(value, function (item) { return '01234567890ABCDEFGHJKLMNPRSTUVWXYZabcdefghjklmnprstuvwxyz-'.indexOf(item) != -1; });
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var hasOnlyLatinOrCirillicOrDigitsOrUnderscore = function (value) {
    return _.every(value, 
    // eslint-disable-next-line max-len
    function (item) { return '01234567890ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyzАБВГДЕЁЖЗИЙКЛМНОПРСТУФХЦЧШЩЪЫЬЭЮЯабвгдеёжзийклмнопрстуфхцчшщъыьэюя-'.indexOf(item) != -1; });
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var formatPriceWithCurrency = function (price) {
    var formatter = new Intl.NumberFormat('ru-RU', { style: 'currency', currency: 'RUB', maximumFractionDigits: 0 });
    return formatter.format(price);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var formatNumber = function (number) {
    var formatter = new Intl.NumberFormat('ru-RU', { style: 'decimal', minimumFractionDigits: 0,
        maximumFractionDigits: 0 });
    return formatter.format(number);
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var getSelectValue = function (value, options) {
    if (_.isArray(value)) {
        var names_1 = _.map(options, 'value');
        var newNames = _.compact(_.map(value, function (item) { return !_.includes(names_1, item)
            ? { value: item, label: item }
            : null; }));
        var values = _.compact(_.map(options, function (item) {
            return _.includes(value, item.value) ? item : null;
        }));
        return _.concat(values, newNames);
    }
    if (value != undefined && options) {
        var obj = _.find(options, function (item) { return item.value == value; });
        return obj ? obj : { value: value, label: value };
    }
    return { value: '', label: '' };
};
