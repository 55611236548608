var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Pagination from 'react-bootstrap/Pagination';
import Select from 'react-select';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Paging(_a) {
    var previousPage = _a.previousPage, nextPage = _a.nextPage, pageCount = _a.pageCount, canPreviousPage = _a.canPreviousPage, canNextPage = _a.canNextPage, gotoPage = _a.gotoPage, pageIndex = _a.pageIndex, setPageSize = _a.setPageSize, pageSize = _a.pageSize;
    var options = [
        { value: 20, label: 20 },
        { value: 50, label: 50 },
        { value: 100, label: 100 }
    ];
    return (_jsx(_Fragment, { children: _jsxs(Pagination, { children: [_jsx(Pagination.First, { className: 'd-flex align-items-center', onClick: function () { return gotoPage(0); }, disabled: !canPreviousPage }), _jsx(Pagination.Prev, { className: 'd-flex align-items-center', onClick: function () { return previousPage(); }, disabled: !canPreviousPage }), paginationItems(pageCount, gotoPage, pageIndex), _jsx(Pagination.Next, { className: 'd-flex align-items-center', onClick: function () { return nextPage(); }, disabled: !canNextPage }), _jsx(Pagination.Last, { className: 'd-flex align-items-center', onClick: function () { return gotoPage(pageCount - 1); }, disabled: !canNextPage }), _jsx(Select, { className: 'mx-4', defaultValue: { value: pageSize, label: pageSize }, onChange: function (e) { return setPageSize(Number(e === null || e === void 0 ? void 0 : e.value)); }, options: options })] }) }));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function paginationItems(pageCount, gotoPage, pageIndex) {
    var items = [];
    var start = 1;
    var end = pageCount;
    if (pageIndex > 1) {
        start = pageIndex;
    }
    if (pageIndex + 1 == pageCount) {
        start = pageIndex - 1;
    }
    if (pageIndex + 1 < pageCount) {
        end = pageIndex + 2;
    }
    if (pageIndex == 0) {
        end = pageIndex + 3;
    }
    if (pageCount == 1 || pageCount == 0) {
        start = 1;
        end = 1;
    }
    if (pageCount == 2) {
        start = 1;
        end = 2;
    }
    var _loop_1 = function (num) {
        items.push(_jsx(Pagination.Item, __assign({ className: 'd-flex align-items-center', onClick: function () { return gotoPage(num - 1); }, active: pageIndex + 1 == num }, { children: num }), num));
    };
    for (var num = start; num <= end; num++) {
        _loop_1(num);
    }
    return items;
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Paging;
