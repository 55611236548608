var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { createElement as _createElement } from "react";
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
/* eslint-disable max-len */
import { useEffect, useRef, useState, useCallback } from 'react';
import _ from 'lodash';
import InputMask from 'react-input-mask';
import Datetime from 'react-datetime';
import { FaCalendar, FaSearch } from 'react-icons/fa';
import Select from 'react-select';
import Creatable from 'react-select/creatable';
import moment from 'moment';
import { monthRusMap } from '../../helpers';
import { useOutsideClick, useUrl } from '../../hooks';
import { useAppDispatch } from '../../hooks/redux';
import { Form, InputGroup, Button } from './bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var CheckboxTextInput = function (_a) {
    var register = _a.register, errors = _a.errors, name = _a.name, label = _a.label, checkboxName = _a.checkboxName, checkboxLabel = _a.checkboxLabel, _b = _a.canEdit, canEdit = _b === void 0 ? true : _b, placeholder = _a.placeholder, textarea = _a.textarea, watch = _a.watch, setValue = _a.setValue, _c = _a.mb, mb = _c === void 0 ? 2 : _c, onChange = _a.onChange, value = _a.value, maxLength = _a.maxLength, toUpperCase = _a.toUpperCase;
    var error = _.get(errors, name);
    var hasNoValue = watch(checkboxName);
    var inputProperties = __assign({ placeholder: placeholder, readOnly: hasNoValue || !canEdit, as: textarea && 'textarea', className: "".concat(error ? 'is-invalid' : '') }, maxLength && { maxLength: maxLength });
    var handleChange = function (event) {
        var value = event.target.value;
        if (toUpperCase) {
            onChange(_.toUpper(value));
        }
        else {
            onChange(value);
        }
    };
    useEffect(function () {
        if (hasNoValue && setValue) {
            setValue(name, '');
        }
    }, [hasNoValue]);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label &&
                _jsxs("div", __assign({ className: 'd-flex' }, { children: [_jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), checkboxName &&
                            _jsxs("div", __assign({ className: 'd-flex ml-2' }, { children: [_jsx("div", __assign({ className: 'mr-2' }, { children: "(" })), _jsx(Form.Check, __assign({}, register(checkboxName, { disabled: !canEdit }), { id: name, className: 'pl-0', type: 'checkbox', label: checkboxLabel || 'Отсутствует' })), _jsx("div", __assign({ className: 'ml-2' }, { children: ")" }))] }))] })), !onChange
                ? _jsx(Form.Control, __assign({}, register(name), inputProperties))
                : _jsx(Form.Control, __assign({ onChange: handleChange, value: value || '' }, inputProperties)), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var TextInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, _c = _a.placeholder, placeholder = _c === void 0 ? '' : _c, textarea = _a.textarea, _d = _a.canEdit, canEdit = _d === void 0 ? true : _d, onChange = _a.onChange, type = _a.type, maxLength = _a.maxLength, toUpperCase = _a.toUpperCase, value = _a.value, needSetUrl = _a.needSetUrl, max = _a.max, min = _a.min;
    var setUrlParams = useUrl().setUrlParams;
    var error = _.get(errors, name);
    var inputProperties = __assign(__assign(__assign({ placeholder: placeholder, type: type || 'text', readOnly: !canEdit, as: textarea && 'textarea' }, maxLength && { maxLength: maxLength }), max && { max: max }), min && { min: min });
    var handleChange = function (event) {
        var _a, _b;
        var value = event.target.value;
        if (toUpperCase) {
            onChange(_.toUpper(value));
            if (needSetUrl) {
                setUrlParams((_a = {},
                    _a[name] = _.toUpper(value),
                    _a));
            }
        }
        else {
            onChange(value);
            setUrlParams((_b = {},
                _b[name] = value,
                _b));
        }
    };
    var handleBlur = function () {
        if (min && min > value) {
            onChange('');
        }
        if (max && max < value) {
            onChange('');
        }
    };
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), register
                ? _jsx(Form.Control, __assign({}, register(name), { className: "".concat(error ? 'is-invalid' : '') }, inputProperties))
                : _jsx(Form.Control, __assign({ onChange: handleChange, onBlur: handleBlur, value: value || '', className: "".concat(error ? 'is-invalid' : '') }, inputProperties)), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var CheckboxInput = function (_a) {
    var name = _a.name, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, register = _a.register, _c = _a.canEdit, canEdit = _c === void 0 ? true : _c, onChange = _a.onChange, checked = _a.checked;
    return _jsx(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: register
            ? _jsx(Form.Check, __assign({}, register(name, { disabled: !canEdit }), { id: name, className: 'pl-0', type: 'checkbox', label: label || '' }))
            : _jsx(Form.Check, { id: name, className: 'pl-0', type: 'checkbox', label: label || '', checked: checked, onChange: onChange }) }));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var FileInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, fileName = _a.fileName, _c = _a.placeholder, placeholder = _c === void 0 ? '' : _c, _d = _a.canEdit, canEdit = _d === void 0 ? true : _d;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), _jsxs("div", __assign({ className: 'position-relative' }, { children: [_jsx(Form.Label, __assign({ className: "".concat(error ? 'is-invalid' : '', " custom-file-label form-control") }, { children: fileName })), _jsx(Form.Control, __assign({}, register(name), { readOnly: !canEdit, placeholder: placeholder, className: 'custom-file-input', type: 'file' }))] })), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var NumberInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, _c = _a.placeholder, placeholder = _c === void 0 ? '' : _c, _d = _a.canEdit, canEdit = _d === void 0 ? true : _d, autoComplete = _a.autoComplete;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), _jsx(Form.Control, __assign({}, register(name), { type: 'number', readOnly: !canEdit, placeholder: placeholder, className: "".concat(error ? 'is-invalid' : ''), autoComplete: autoComplete })), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var SelectInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, disabled = _a.disabled, values = _a.values, _c = _a.emptyValue, emptyValue = _c === void 0 ? '- - -' : _c, _d = _a.optionName, optionName = _d === void 0 ? 'name' : _d, defaultValue = _a.defaultValue;
    var error = _.get(errors, name);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), _jsxs(Form.Select, __assign({}, register(name), { className: "custom-select ".concat(error ? 'is-invalid' : ''), disabled: disabled, defaultValue: defaultValue }, { children: [_jsx("option", __assign({ value: '' }, { children: emptyValue })), _.map(values, function (value, index) {
                        return _jsx("option", __assign({ value: value.id || value }, { children: _.get(value, optionName) }), value.id || index);
                    })] })), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var ReactSelect = function (_a) {
    var name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, _c = _a.placeholder, placeholder = _c === void 0 ? '' : _c, toUpperCase = _a.toUpperCase, selectedValue = _a.selectedValue, values = _a.values, defaultValue = _a.defaultValue, onChange = _a.onChange, isCreatable = _a.isCreatable, isMulti = _a.isMulti, _d = _a.canEdit, canEdit = _d === void 0 ? true : _d;
    var error = _.get(errors, name);
    var _e = __read(useState(''), 2), inputValue = _e[0], setInputValue = _e[1];
    var handleChange = function (newValue) {
        if (isMulti) {
            var value = _.map(newValue, function (item) { return item.value ? item.value : item; });
            onChange(value);
        }
        else {
            if (selectedValue) {
                selectedValue(newValue);
            }
            onChange(newValue.value);
            setInputValue(newValue.label);
        }
    };
    var handleBlur = function () {
        if (inputValue) {
            var value = _.map(defaultValue, function (item) { return item.value ? item.value : item; });
            if (isMulti && !_.includes(value, inputValue)) {
                onChange(__spreadArray(__spreadArray([], __read(value), false), [inputValue], false));
            }
            if (!isMulti) {
                if (selectedValue) {
                    selectedValue(inputValue);
                }
                onChange(inputValue);
                setInputValue(inputValue);
            }
        }
    };
    var handleInputChange = function (newValue, _a) {
        var action = _a.action;
        // only set the input when the action that caused the
        // change equals to "input-change" and ignore the other
        // ones like: "set-value", "input-blur", and "menu-close"
        if (action === 'input-change') {
            if (toUpperCase) {
                setInputValue(_.toUpper(newValue));
            }
            else {
                setInputValue(newValue);
            }
            if (!newValue && !isMulti) {
                onChange('');
            }
        }
        if (isMulti) {
            setInputValue(newValue);
        }
    };
    var noOptionsMessage = function () {
        var text = 'Ничего не найдено';
        return text;
    };
    var errorStyles = {
        paddingRight: 'calc(1.5em + 0.75rem);',
        backgroundImage: 'url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23e74a3b%27 viewBox=%270 0 12 12%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23e74a3b%27 stroke=%27none%27/%3e%3c/svg%3e")',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right calc(0.375em + 0.1875rem) center',
        backgroundSize: 'calc(0.75em + 0.375rem) calc(0.75em + 0.375rem)'
    };
    var styles = {
        control: function (provided, state) { return (__assign(__assign(__assign(__assign(__assign({}, provided), error ? { borderColor: '#e74a3b !important' } : {}), state.isFocused ? { boxShadow: '0 0 0 3px rgba(13, 110, 253, .25)' } : {}), error && state.isFocused ? { boxShadow: '0 0 0 3px rgba(231, 74, 59, .25)' } : {}), canEdit ? {} : { backgroundColor: '#eaecf4' })); },
        dropdownIndicator: function (provided) { return (__assign(__assign({}, provided), error ? { color: '#e74a3b !important' } : {})); },
        indicatorSeparator: function (provided) { return (__assign(__assign({}, provided), error ? { backgroundColor: '#e74a3b !important' } : {})); },
        valueContainer: function (provided) { return (__assign(__assign({}, provided), error ? errorStyles : {})); }
    };
    var selectProperties = {
        className: error ? 'is-invalid' : '',
        classNamePrefix: 'react-select',
        styles: styles,
        placeholder: placeholder,
        options: values,
        value: defaultValue,
        onChange: handleChange,
        isMulti: isMulti,
        isDisabled: !canEdit,
        noOptionsMessage: noOptionsMessage
    };
    useEffect(function () {
        if (!isMulti) {
            setInputValue(defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.label);
        }
    }, [defaultValue]);
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), isCreatable
                ? _jsx(Creatable, __assign({}, selectProperties, { onBlur: handleBlur, onInputChange: handleInputChange, inputValue: inputValue, formatCreateLabel: function (value) { return value; } }))
                : _jsx(Select, __assign({}, selectProperties)), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var DictionarySelectInput = function (_a) {
    var _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, _c = _a.optionName, optionName = _c === void 0 ? 'name' : _c, _d = _a.withoutEmpty, withoutEmpty = _d === void 0 ? false : _d, values = _a.values, _e = _a.emptyValue, emptyValue = _e === void 0 ? '- - -' : _e, action = _a.action, defaultValue = _a.defaultValue, disabled = _a.disabled;
    var dispatch = useAppDispatch();
    var handleChange = function (event) {
        var selectedValue = event.target.value;
        dispatch(action(selectedValue));
    };
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'font-weight-bold' }, { children: label })), _jsxs(Form.Select, __assign({ className: 'custom-select', onChange: handleChange, value: defaultValue, disabled: disabled }, { children: [!withoutEmpty && _jsx("option", __assign({ value: '' }, { children: emptyValue })), _.map(values, function (value) {
                        return _jsx("option", __assign({ value: value.id }, { children: value[optionName] }), value.id);
                    })] }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var SearchInput = function (_a) {
    var _b = _a.mb, mb = _b === void 0 ? 2 : _b, action = _a.action, defaultValue = _a.defaultValue, onChange = _a.onChange;
    var dispatch = useAppDispatch();
    var dispatchAction = useCallback(_.debounce(function (searchString) {
        dispatch(action(searchString));
    }, 500), []);
    var handleChange = function (event) {
        var searchString = event.target.value;
        dispatchAction(searchString);
    };
    return (_jsxs(InputGroup, __assign({ className: "mb-".concat(mb) }, { children: [_jsx(Form.Control, { defaultValue: defaultValue, placeholder: '\u041F\u043E\u0438\u0441\u043A', onChange: onChange ? onChange : handleChange }), _jsx(InputGroup.Text, { children: _jsx(FaSearch, { fill: 'var(--white)' }) })] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var DateInput = function (_a) {
    var _b = _a.label, label = _b === void 0 ? '' : _b, _c = _a.mb, mb = _c === void 0 ? 0 : _c, _d = _a.name, name = _d === void 0 ? '' : _d, action = _a.action, onChange = _a.onChange, _e = _a.defaultValue, defaultValue = _e === void 0 ? '' : _e, errors = _a.errors, _f = _a.canEdit, canEdit = _f === void 0 ? true : _f;
    var _g = __read(useState(''), 2), value = _g[0], setValue = _g[1];
    var _h = __read(useState(false), 2), showCalendar = _h[0], setShowCalendar = _h[1];
    var dateCalendarRef = useRef(null);
    var dispatch = useAppDispatch();
    var error = _.get(errors, name);
    var setDateFormat = function (text, inputFormat, outputFormat) {
        if (inputFormat === void 0) { inputFormat = 'DDMMYYYY'; }
        if (outputFormat === void 0) { outputFormat = 'DD.MM.YYYY'; }
        var value = (text + '').replace(/[^\d]/g, '');
        if (value) {
            var time = moment(value, inputFormat);
            if (time.isValid()) {
                value = time.format(outputFormat);
            }
            else {
                value = '';
            }
        }
        else {
            value = '';
        }
        return value;
    };
    useEffect(function () {
        setValue(defaultValue);
    }, [defaultValue]);
    var handleChange = function (event) {
        var date = event.target.value;
        setValue(date);
    };
    var onBlur = function (value) {
        var formatted = setDateFormat(value);
        setValue(formatted);
        if (onChange) {
            onChange(formatted);
        }
        else if (action) {
            dispatch(action(formatted));
        }
    };
    var handleSelectedDay = function (value) {
        var date = moment(value).format('DD.MM.YYYY');
        setValue(setDateFormat(date));
        if (onChange) {
            onChange(date);
        }
        else if (action) {
            dispatch(action(date));
        }
        setShowCalendar(false);
    };
    var inputProperties = {
        className: "pl-0.375 ".concat(error ? 'is-invalid' : ''),
        value: value,
        mask: '99.99.9999',
        readOnly: !canEdit,
        alwaysShowMask: true,
        onChange: handleChange,
        onBlur: function () { return onBlur(value); }
    };
    var calendarProperties = {
        className: 'datetime date-start',
        bsSize: 'small',
        value: value,
        onChange: function (value) { return handleSelectedDay(value); },
        closeOnSelect: true,
        disableOnClickOutside: true,
        dateFormat: 'DD.MM.YYYY',
        timeFormat: false,
        input: false,
        locale: 'ru',
        renderMonth: function (props, month) {
            return _createElement("td", __assign({}, props, { className: 'p-2', key: month }), _.upperFirst(monthRusMap[month]));
        }
    };
    if (dateCalendarRef) {
        useOutsideClick(dateCalendarRef, setShowCalendar);
    }
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'mr-2 font-weight-bold' }, { children: label })), _jsxs(InputGroup, __assign({ className: error ? 'is-invalid' : '' }, { children: [_jsx(InputMask, __assign({}, inputProperties, { children: _jsx(Form.Control, { type: 'text' }) })), _jsx(Button, __assign({ variant: 'primary', className: 'd-flex align-items-center', onClick: function () { return setShowCalendar(!showCalendar); }, disabled: !canEdit }, { children: _jsx(FaCalendar, {}) }))] })), showCalendar && _jsx("div", __assign({ ref: dateCalendarRef }, { children: _jsx(Datetime, __assign({}, calendarProperties)) })), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export var PhoneInput = function (_a) {
    var register = _a.register, name = _a.name, errors = _a.errors, _b = _a.mb, mb = _b === void 0 ? 2 : _b, label = _a.label, _c = _a.canEdit, canEdit = _c === void 0 ? true : _c;
    var error = _.get(errors, name);
    var inputProperties = {
        className: "pl-0.375 form-control ".concat(error ? 'is-invalid' : ''),
        mask: '+7 (999) 999-99-99',
        alwaysShowMask: true,
        readOnly: !canEdit
    };
    return (_jsxs(Form.Group, __assign({ className: "mb-".concat(mb) }, { children: [label && _jsx(Form.Label, __assign({ className: 'mr-2 font-weight-bold' }, { children: label })), _jsx(InputMask, __assign({}, register(name), inputProperties)), (error === null || error === void 0 ? void 0 : error.message) && _jsx(Form.Control.Feedback, __assign({ type: 'invalid' }, { children: error.message }))] })));
};
