var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import _ from 'lodash';
import { FaDownload } from 'react-icons/fa';
import { ButtonGroup, ButtonToolbar } from './bootstrap';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function PhotoCard(_a) {
    var title = _a.title, subtitle = _a.subtitle, url = _a.url, description = _a.description, buttons = _a.buttons, className = _a.className;
    var icons = {
        download: _jsx(FaDownload, {})
    };
    return (_jsxs("div", __assign({ className: "card h-100 ".concat(className) }, { children: [_jsxs("div", __assign({ className: 'card-body' }, { children: [title &&
                        _jsx("h5", __assign({ className: 'card-title' }, { children: title })), subtitle &&
                        _jsx("h6", __assign({ className: 'card-subtitle text-muted' }, { children: subtitle }))] })), _jsx("img", { className: 'img-fluid', src: url, alt: 'Card image cap' }), _jsxs("div", __assign({ className: 'card-body' }, { children: [description &&
                        _jsx("div", __assign({ className: 'card-text' }, { children: description })), buttons &&
                        _jsx(ButtonToolbar, { children: _jsx(ButtonGroup, __assign({ className: 'me-2' }, { children: _.map(buttons, function (item) {
                                    return _jsxs("a", __assign({ href: url, download: true, className: 'd-flex align-items-center btn btn-primary' }, { children: [_.get(icons, item.icon), item.title &&
                                                _jsx("span", __assign({ className: 'card-btn__title' }, { children: item.title }))] }), item.icon);
                                }) })) })] }))] })));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default PhotoCard;
