var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __read = (this && this.__read) || function (o, n) {
    var m = typeof Symbol === "function" && o[Symbol.iterator];
    if (!m) return o;
    var i = m.call(o), r, ar = [], e;
    try {
        while ((n === void 0 || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
    }
    catch (error) { e = { error: error }; }
    finally {
        try {
            if (r && !r.done && (m = i["return"])) m.call(i);
        }
        finally { if (e) throw e.error; }
    }
    return ar;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { DateInput, TextInput } from '../../components/common/inputs';
import { Form, Panel, Row, Col, Button, Alert } from '../../components/common/bootstrap';
import { toServerDateOnly } from '../../helpers';
import { autoScannerAPI } from '../../api';
import Spinner from '../../components/common/Spinner';
import { useAppDispatch } from '../../hooks/redux';
import { setCardsData } from '../../redux/slices';
import { useUrl } from '../../hooks';
import DictionarySelect from '../../components/common/DictionarySelect';
import makes from '../../helpers/makes';
import models from '../../helpers/models';
import MultiSelect from '../../components/common/MultiSelect';
import { categories } from '../../helpers/constants';
import { searchSchema } from './validation';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function Home() {
    var _this = this;
    var navigate = useNavigate();
    var dispatch = useAppDispatch();
    var _a = useUrl(), urlParams = _a.urlParams, setUrlParams = _a.setUrlParams;
    var _b = __read(autoScannerAPI.useGetCardsMutation(), 2), getCards = _b[0], _c = _b[1], data = _c.data, isLoading = _c.isLoading;
    var _d = __read(useState(''), 2), error = _d[0], setError = _d[1];
    var _e = __read(useState({ value: '', label: '', id: null }), 2), selectedMake = _e[0], setSelectedMake = _e[1];
    var _f = __read(useState(models), 2), filteredModels = _f[0], setFilteredModels = _f[1];
    var initialObject = {
        from: '01.03.2021',
        to: '30.11.2022',
        vinNumber: '',
        chassisNumber: '',
        bodyNumber: '',
        subCategories: [],
        make: '',
        model: '',
        years: { from: '', to: '' },
        registerSign: ''
    };
    var _g = useForm({
        resolver: yupResolver(searchSchema),
        mode: 'onSubmit',
        defaultValues: initialObject
    }), handleSubmit = _g.handleSubmit, control = _g.control, errors = _g.formState.errors;
    var getMake = function (newValue) {
        if (_.isString(newValue)) {
            var make = _.find(makes, function (make) { return make.value == newValue; });
            return make ? make : { value: newValue, label: newValue };
        }
        return newValue;
    };
    var handleSelectMake = function (newValue) {
        var make = getMake(newValue);
        if (selectedMake.value != make.value && selectedMake.label != make.label) {
            setSelectedMake(make);
        }
    };
    // eslint-disable-next-line complexity
    var handleSearch = function (_a) {
        var from = _a.from, to = _a.to, vinNumber = _a.vinNumber, chassisNumber = _a.chassisNumber, bodyNumber = _a.bodyNumber, subCategories = _a.subCategories, make = _a.make, model = _a.model, registerSign = _a.registerSign, years = _a.years;
        return __awaiter(_this, void 0, void 0, function () {
            var hasFilter, params;
            return __generator(this, function (_b) {
                switch (_b.label) {
                    case 0:
                        hasFilter = make || model || registerSign || vinNumber || chassisNumber
                            || bodyNumber || !_.isEmpty(subCategories) || years.from || years.to;
                        if (!(from && to)) return [3 /*break*/, 4];
                        if (!!hasFilter) return [3 /*break*/, 1];
                        setError('Необходимо ввести хотя бы один фильтр');
                        return [3 /*break*/, 4];
                    case 1:
                        if (!(years.from > years.to)) return [3 /*break*/, 2];
                        setError('Некорректный диапазон года');
                        return [3 /*break*/, 4];
                    case 2:
                        setError('');
                        params = __assign(__assign(__assign(__assign(__assign(__assign(__assign(__assign({}, vinNumber && { vinNumber: vinNumber }), make && { make: make }), model && { model: model }), registerSign && { registerSign: registerSign }), chassisNumber && { chassisNumber: chassisNumber }), bodyNumber && { bodyNumber: bodyNumber }), subCategories && { subCategories: subCategories }), years.from && years.to && { years: years });
                        setUrlParams(__assign({ from: from, to: to }, params));
                        return [4 /*yield*/, getCards(__assign({ from: toServerDateOnly(from), to: toServerDateOnly(to) }, params))];
                    case 3:
                        _b.sent();
                        _b.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        });
    };
    useEffect(function () {
        var params = new URLSearchParams(urlParams).toString();
        if (data) {
            dispatch(setCardsData(data));
            navigate("/cards?".concat(params));
        }
    }, [data]);
    useEffect(function () {
        var filterModels = _.filter(models, function (model) { return model.makeId == (selectedMake === null || selectedMake === void 0 ? void 0 : selectedMake.id); });
        setFilteredModels(filterModels);
    }, [selectedMake]);
    return (_jsx("div", __assign({ className: 'home' }, { children: _jsx(Row, { children: _jsx(Col, __assign({ md: 8, lg: 6, sm: 10, className: 'm-auto' }, { children: _jsx(Panel, __assign({ header: 'AUTO SCANNER' }, { children: _jsxs(Form, { children: [_jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'from', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(DateInput, { label: '\u0414\u0430\u0442\u0430 \u043E\u0442', onChange: onChange, defaultValue: value, name: 'from', errors: errors });
                                            } }) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'to', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(DateInput, { label: '\u0414\u0430\u0442\u0430 \u0434\u043E', onChange: onChange, defaultValue: value, name: 'to', errors: errors });
                                            } }) }))] }), _jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'registerSign', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(TextInput, { label: '\u0413\u043E\u0441. \u043D\u043E\u043C\u0435\u0440', name: 'registerSign', onChange: onChange, value: value, errors: errors, toUpperCase: true });
                                            } }) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'vinNumber', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(TextInput, { label: 'VIN \u043D\u043E\u043C\u0435\u0440', name: 'vinNumber', onChange: onChange, value: value, errors: errors, maxLength: 17, toUpperCase: true });
                                            } }) }))] }), _jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'chassisNumber', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(TextInput, { label: '\u041D\u043E\u043C\u0435\u0440 \u0448\u0430\u0441\u0441\u0438', name: 'chassisNumber', onChange: onChange, value: value, errors: errors, toUpperCase: true });
                                            } }) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'bodyNumber', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(TextInput, { label: '\u041D\u043E\u043C\u0435\u0440 \u043A\u0443\u0437\u043E\u0432\u0430', name: 'bodyNumber', onChange: onChange, value: value, errors: errors, toUpperCase: true });
                                            } }) }))] }), _jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'years.from', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(TextInput, { label: '\u0413\u043E\u0434 \u0432\u044B\u043F\u0443\u0441\u043A\u0430 ( \u043E\u0442 )', name: 'years.from', type: 'number', min: 1900, max: +moment().format('YYYY'), onChange: onChange, value: value, errors: errors, toUpperCase: true });
                                            } }) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'years.to', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(TextInput, { label: '\u0413\u043E\u0434 \u0432\u044B\u043F\u0443\u0441\u043A\u0430 ( \u043F\u043E )', name: 'years.to', type: 'number', min: 1900, max: +moment().format('YYYY'), onChange: onChange, value: value, errors: errors, toUpperCase: true });
                                            } }) }))] }), _jsx(Row, { children: _jsx(Col, __assign({ md: 12 }, { children: _jsx(Controller, { control: control, name: 'subCategories', render: function (_a) {
                                            var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                            return _jsx(MultiSelect, { onChange: onChange, value: value, values: categories, label: '\u041A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u044F', name: 'subCategories', errors: errors, isCreatable: true });
                                        } }) })) }), _jsxs(Row, { children: [_jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'make', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(DictionarySelect, { onChange: onChange, value: value, values: makes, label: '\u041C\u0430\u0440\u043A\u0430', name: 'make', selectedValue: handleSelectMake, errors: errors, isCreatable: true, toUpperCase: true });
                                            } }) })), _jsx(Col, __assign({ md: 6 }, { children: _jsx(Controller, { control: control, name: 'model', render: function (_a) {
                                                var _b = _a.field, onChange = _b.onChange, value = _b.value;
                                                return _jsx(DictionarySelect, { onChange: onChange, value: value, values: filteredModels, label: '\u041C\u043E\u0434\u0435\u043B\u044C', name: 'model', errors: errors, isCreatable: true, toUpperCase: true });
                                            } }) }))] }), _jsx("div", __assign({ className: 'mb-4' }, { children: "\u0411\u0443\u0434\u0443\u0442 \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u044B 1000 \u0441\u0430\u043C\u044B\u0445 \u043F\u043E\u0441\u043B\u0435\u0434\u043D\u0438\u0445 \u043A\u0430\u0440\u0442" })), error && _jsx(Alert, __assign({ variant: 'danger' }, { children: error })), _jsx(Button, __assign({ variant: 'primary', onClick: handleSubmit(handleSearch), disabled: isLoading }, { children: isLoading
                                    ? _jsx(Spinner, {})
                                    : 'Искать' }))] }) })) })) }) })));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default Home;
