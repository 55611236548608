/* eslint-disable max-lines */
/* eslint-disable semi */
var makes = [
    {
        value: 'AC',
        label: 'AC',
        id: 1
    },
    {
        value: 'ACURA',
        label: 'ACURA',
        id: 2
    },
    {
        value: 'ADLER',
        label: 'ADLER',
        id: 3
    },
    {
        value: 'ALFA ROMEO',
        label: 'ALFA ROMEO',
        id: 4
    },
    {
        value: 'ALPINA',
        label: 'ALPINA',
        id: 5
    },
    {
        value: 'ALPINE',
        label: 'ALPINE',
        id: 6
    },
    {
        value: 'AM GENERAL',
        label: 'AM GENERAL',
        id: 7
    },
    {
        value: 'AMC',
        label: 'AMC',
        id: 8
    },
    {
        value: 'ARIEL',
        label: 'ARIEL',
        id: 9
    },
    {
        value: 'ARO',
        label: 'ARO',
        id: 10
    },
    {
        value: 'ASIA',
        label: 'ASIA',
        id: 11
    },
    {
        value: 'ASTON MARTIN',
        label: 'ASTON MARTIN',
        id: 12
    },
    {
        value: 'AUDI',
        label: 'AUDI',
        id: 13
    },
    {
        value: 'AURUS',
        label: 'AURUS',
        id: 14
    },
    {
        value: 'AUSTIN',
        label: 'AUSTIN',
        id: 15
    },
    {
        value: 'AUSTIN HEALEY',
        label: 'AUSTIN HEALEY',
        id: 16
    },
    {
        value: 'AUTOBIANCHI',
        label: 'AUTOBIANCHI',
        id: 17
    },
    {
        value: 'BAIC',
        label: 'BAIC',
        id: 18
    },
    {
        value: 'BAJAJ',
        label: 'BAJAJ',
        id: 19
    },
    {
        value: 'BALTIJAS DZIPS',
        label: 'BALTIJAS DZIPS',
        id: 20
    },
    {
        value: 'BATMOBILE',
        label: 'BATMOBILE',
        id: 21
    },
    {
        value: 'BENTLEY',
        label: 'BENTLEY',
        id: 22
    },
    {
        value: 'BERTONE',
        label: 'BERTONE',
        id: 23
    },
    {
        value: 'BILENKIN',
        label: 'BILENKIN',
        id: 24
    },
    {
        value: 'BITTER',
        label: 'BITTER',
        id: 25
    },
    {
        value: 'BMW',
        label: 'BMW',
        id: 26
    },
    {
        value: 'BORGWARD',
        label: 'BORGWARD',
        id: 27
    },
    {
        value: 'BRABUS',
        label: 'BRABUS',
        id: 28
    },
    {
        value: 'BRILLIANCE',
        label: 'BRILLIANCE',
        id: 29
    },
    {
        value: 'BRISTOL',
        label: 'BRISTOL',
        id: 30
    },
    {
        value: 'BUFORI',
        label: 'BUFORI',
        id: 31
    },
    {
        value: 'BUGATTI',
        label: 'BUGATTI',
        id: 32
    },
    {
        value: 'BUICK',
        label: 'BUICK',
        id: 33
    },
    {
        value: 'BYD',
        label: 'BYD',
        id: 34
    },
    {
        value: 'BYVIN',
        label: 'BYVIN',
        id: 35
    },
    {
        value: 'CADILLAC',
        label: 'CADILLAC',
        id: 36
    },
    {
        value: 'CALLAWAY',
        label: 'CALLAWAY',
        id: 37
    },
    {
        value: 'CARBODIES',
        label: 'CARBODIES',
        id: 38
    },
    {
        value: 'CATERHAM',
        label: 'CATERHAM',
        id: 39
    },
    {
        value: 'CHANA',
        label: 'CHANA',
        id: 40
    },
    {
        value: 'CHANGAN',
        label: 'CHANGAN',
        id: 41
    },
    {
        value: 'CHANGFENG',
        label: 'CHANGFENG',
        id: 42
    },
    {
        value: 'CHANGHE',
        label: 'CHANGHE',
        id: 43
    },
    {
        value: 'CHERY',
        label: 'CHERY',
        id: 44
    },
    {
        value: 'CHEVROLET',
        label: 'CHEVROLET',
        id: 45
    },
    {
        value: 'CHRYSLER',
        label: 'CHRYSLER',
        id: 46
    },
    {
        value: 'CITROEN',
        label: 'CITROEN',
        id: 47
    },
    {
        value: 'CIZETA',
        label: 'CIZETA',
        id: 48
    },
    {
        value: 'COGGIOLA',
        label: 'COGGIOLA',
        id: 49
    },
    {
        value: 'CORD',
        label: 'CORD',
        id: 50
    },
    {
        value: 'DACIA',
        label: 'DACIA',
        id: 51
    },
    {
        value: 'DADI',
        label: 'DADI',
        id: 52
    },
    {
        value: 'DAEWOO',
        label: 'DAEWOO',
        id: 53
    },
    {
        value: 'DAIHATSU',
        label: 'DAIHATSU',
        id: 54
    },
    {
        value: 'DAIMLER',
        label: 'DAIMLER',
        id: 55
    },
    {
        value: 'DATSUN',
        label: 'DATSUN',
        id: 56
    },
    {
        value: 'DE TOMASO',
        label: 'DE TOMASO',
        id: 57
    },
    {
        value: 'DELAGE',
        label: 'DELAGE',
        id: 58
    },
    {
        value: 'DELOREAN',
        label: 'DELOREAN',
        id: 59
    },
    {
        value: 'DERWAYS',
        label: 'DERWAYS',
        id: 60
    },
    {
        value: 'DESOTO',
        label: 'DESOTO',
        id: 61
    },
    {
        value: 'DKW',
        label: 'DKW',
        id: 62
    },
    {
        value: 'DODGE',
        label: 'DODGE',
        id: 63
    },
    {
        value: 'DONGFENG',
        label: 'DONGFENG',
        id: 64
    },
    {
        value: 'DONINVEST',
        label: 'DONINVEST',
        id: 65
    },
    {
        value: 'DONKERVOORT',
        label: 'DONKERVOORT',
        id: 66
    },
    {
        value: 'DS',
        label: 'DS',
        id: 67
    },
    {
        value: 'DW HOWER',
        label: 'DW HOWER',
        id: 68
    },
    {
        value: 'E-CAR',
        label: 'E-CAR',
        id: 69
    },
    {
        value: 'EAGLE',
        label: 'EAGLE',
        id: 70
    },
    {
        value: 'EAGLE CARS',
        label: 'EAGLE CARS',
        id: 71
    },
    {
        value: 'EXCALIBUR',
        label: 'EXCALIBUR',
        id: 72
    },
    {
        value: 'FAW',
        label: 'FAW',
        id: 73
    },
    {
        value: 'FERRARI',
        label: 'FERRARI',
        id: 74
    },
    {
        value: 'FIAT',
        label: 'FIAT',
        id: 75
    },
    {
        value: 'FISKER',
        label: 'FISKER',
        id: 76
    },
    {
        value: 'FLANKER',
        label: 'FLANKER',
        id: 77
    },
    {
        value: 'FORD',
        label: 'FORD',
        id: 78
    },
    {
        value: 'FOTON',
        label: 'FOTON',
        id: 79
    },
    {
        value: 'FSO',
        label: 'FSO',
        id: 80
    },
    {
        value: 'FUQI',
        label: 'FUQI',
        id: 81
    },
    {
        value: 'GAC',
        label: 'GAC',
        id: 82
    },
    {
        value: 'GEELY',
        label: 'GEELY',
        id: 83
    },
    {
        value: 'GENESIS',
        label: 'GENESIS',
        id: 84
    },
    {
        value: 'GEO',
        label: 'GEO',
        id: 85
    },
    {
        value: 'GMC',
        label: 'GMC',
        id: 86
    },
    {
        value: 'GONOW',
        label: 'GONOW',
        id: 87
    },
    {
        value: 'GORDON',
        label: 'GORDON',
        id: 88
    },
    {
        value: 'GP',
        label: 'GP',
        id: 89
    },
    {
        value: 'GREAT WALL',
        label: 'GREAT WALL',
        id: 90
    },
    {
        value: 'HAFEI',
        label: 'HAFEI',
        id: 91
    },
    {
        value: 'HAIMA',
        label: 'HAIMA',
        id: 92
    },
    {
        value: 'HANOMAG',
        label: 'HANOMAG',
        id: 93
    },
    {
        value: 'HAVAL',
        label: 'HAVAL',
        id: 94
    },
    {
        value: 'HAWTAI',
        label: 'HAWTAI',
        id: 95
    },
    {
        value: 'HINDUSTAN',
        label: 'HINDUSTAN',
        id: 96
    },
    {
        value: 'HISPANO-SUIZA',
        label: 'HISPANO-SUIZA',
        id: 97
    },
    {
        value: 'HOLDEN',
        label: 'HOLDEN',
        id: 98
    },
    {
        value: 'HONDA',
        label: 'HONDA',
        id: 99
    },
    {
        value: 'HORCH',
        label: 'HORCH',
        id: 100
    },
    {
        value: 'HUANGHAI',
        label: 'HUANGHAI',
        id: 101
    },
    {
        value: 'HUDSON',
        label: 'HUDSON',
        id: 102
    },
    {
        value: 'HUMMER',
        label: 'HUMMER',
        id: 103
    },
    {
        value: 'HYUNDAI',
        label: 'HYUNDAI',
        id: 104
    },
    {
        value: 'INFINITI',
        label: 'INFINITI',
        id: 105
    },
    {
        value: 'INNOCENTI',
        label: 'INNOCENTI',
        id: 106
    },
    {
        value: 'INTERNATIONAL',
        label: 'INTERNATIONAL',
        id: 107
    },
    {
        value: 'INVICTA',
        label: 'INVICTA',
        id: 108
    },
    {
        value: 'IRAN KHODRO',
        label: 'IRAN KHODRO',
        id: 109
    },
    {
        value: 'ISDERA',
        label: 'ISDERA',
        id: 110
    },
    {
        value: 'ISUZU',
        label: 'ISUZU',
        id: 111
    },
    {
        value: 'IVECO',
        label: 'IVECO',
        id: 112
    },
    {
        value: 'JAC',
        label: 'JAC',
        id: 113
    },
    {
        value: 'JAGUAR',
        label: 'JAGUAR',
        id: 114
    },
    {
        value: 'JEEP',
        label: 'JEEP',
        id: 115
    },
    {
        value: 'JENSEN',
        label: 'JENSEN',
        id: 116
    },
    {
        value: 'JINBEI',
        label: 'JINBEI',
        id: 117
    },
    {
        value: 'JMC',
        label: 'JMC',
        id: 118
    },
    {
        value: 'KIA',
        label: 'KIA',
        id: 119
    },
    {
        value: 'KOENIGSEGG',
        label: 'KOENIGSEGG',
        id: 120
    },
    {
        value: 'KTM AG',
        label: 'KTM AG',
        id: 121
    },
    {
        value: 'LADA',
        label: 'LADA (ВАЗ)',
        id: 122
    },
    {
        value: 'LAMBORGHINI',
        label: 'LAMBORGHINI',
        id: 123
    },
    {
        value: 'LANCIA',
        label: 'LANCIA',
        id: 124
    },
    {
        value: 'LAND ROVER',
        label: 'LAND ROVER',
        id: 125
    },
    {
        value: 'LANDWIND',
        label: 'LANDWIND',
        id: 126
    },
    {
        value: 'LEXUS',
        label: 'LEXUS',
        id: 127
    },
    {
        value: 'LIEBAO MOTOR',
        label: 'LIEBAO MOTOR',
        id: 128
    },
    {
        value: 'LIFAN',
        label: 'LIFAN',
        id: 129
    },
    {
        value: 'LIGIER',
        label: 'LIGIER',
        id: 130
    },
    {
        value: 'LINCOLN',
        label: 'LINCOLN',
        id: 131
    },
    {
        value: 'LOTUS',
        label: 'LOTUS',
        id: 132
    },
    {
        value: 'LTI',
        label: 'LTI',
        id: 133
    },
    {
        value: 'LUCID',
        label: 'LUCID',
        id: 134
    },
    {
        value: 'LUXGEN',
        label: 'LUXGEN',
        id: 135
    },
    {
        value: 'MAHINDRA',
        label: 'MAHINDRA',
        id: 136
    },
    {
        value: 'MARCOS',
        label: 'MARCOS',
        id: 137
    },
    {
        value: 'MARLIN',
        label: 'MARLIN',
        id: 138
    },
    {
        value: 'MARUSSIA',
        label: 'MARUSSIA',
        id: 139
    },
    {
        value: 'MARUTI',
        label: 'MARUTI',
        id: 140
    },
    {
        value: 'MASERATI',
        label: 'MASERATI',
        id: 141
    },
    {
        value: 'MAYBACH',
        label: 'MAYBACH',
        id: 142
    },
    {
        value: 'MAZDA',
        label: 'MAZDA',
        id: 143
    },
    {
        value: 'MCLAREN',
        label: 'MCLAREN',
        id: 144
    },
    {
        value: 'MEGA',
        label: 'MEGA',
        id: 145
    },
    {
        value: 'MERCEDES-BENZ',
        label: 'MERCEDES-BENZ',
        id: 146
    },
    {
        value: 'MERCURY',
        label: 'MERCURY',
        id: 147
    },
    {
        value: 'METROCAB',
        label: 'METROCAB',
        id: 148
    },
    {
        value: 'MG',
        label: 'MG',
        id: 149
    },
    {
        value: 'MICROCAR',
        label: 'MICROCAR',
        id: 150
    },
    {
        value: 'MINELLI',
        label: 'MINELLI',
        id: 151
    },
    {
        value: 'MINI',
        label: 'MINI',
        id: 152
    },
    {
        value: 'MITSUBISHI',
        label: 'MITSUBISHI',
        id: 153
    },
    {
        value: 'MITSUOKA',
        label: 'MITSUOKA',
        id: 154
    },
    {
        value: 'MORGAN',
        label: 'MORGAN',
        id: 155
    },
    {
        value: 'MORRIS',
        label: 'MORRIS',
        id: 156
    },
    {
        value: 'NASH',
        label: 'NASH',
        id: 157
    },
    {
        value: 'NISSAN',
        label: 'NISSAN',
        id: 158
    },
    {
        value: 'NOBLE',
        label: 'NOBLE',
        id: 159
    },
    {
        value: 'OLDSMOBILE',
        label: 'OLDSMOBILE',
        id: 160
    },
    {
        value: 'OPEL',
        label: 'OPEL',
        id: 161
    },
    {
        value: 'OSCA',
        label: 'OSCA',
        id: 162
    },
    {
        value: 'PACKARD',
        label: 'PACKARD',
        id: 163
    },
    {
        value: 'PAGANI',
        label: 'PAGANI',
        id: 164
    },
    {
        value: 'PANOZ',
        label: 'PANOZ',
        id: 165
    },
    {
        value: 'PERODUA',
        label: 'PERODUA',
        id: 166
    },
    {
        value: 'PEUGEOT',
        label: 'PEUGEOT',
        id: 167
    },
    {
        value: 'PGO',
        label: 'PGO',
        id: 168
    },
    {
        value: 'PIAGGIO',
        label: 'PIAGGIO',
        id: 169
    },
    {
        value: 'PLYMOUTH',
        label: 'PLYMOUTH',
        id: 170
    },
    {
        value: 'PONTIAC',
        label: 'PONTIAC',
        id: 171
    },
    {
        value: 'PORSCHE',
        label: 'PORSCHE',
        id: 172
    },
    {
        value: 'PREMIER',
        label: 'PREMIER',
        id: 173
    },
    {
        value: 'PROTON',
        label: 'PROTON',
        id: 174
    },
    {
        value: 'PUCH',
        label: 'PUCH',
        id: 175
    },
    {
        value: 'PUMA',
        label: 'PUMA',
        id: 176
    },
    {
        value: 'QOROS',
        label: 'QOROS',
        id: 177
    },
    {
        value: 'QVALE',
        label: 'QVALE',
        id: 178
    },
    {
        value: 'RAMBLER',
        label: 'RAMBLER',
        id: 179
    },
    {
        value: 'RAVON',
        label: 'RAVON',
        id: 180
    },
    {
        value: 'RELIANT',
        label: 'RELIANT',
        id: 181
    },
    {
        value: 'RENAISSANCE',
        label: 'RENAISSANCE',
        id: 182
    },
    {
        value: 'RENAULT',
        label: 'RENAULT',
        id: 183
    },
    {
        value: 'RENAULT SAMSUNG',
        label: 'RENAULT SAMSUNG',
        id: 184
    },
    {
        value: 'REZVANI',
        label: 'REZVANI',
        id: 185
    },
    {
        value: 'RIMAC',
        label: 'RIMAC',
        id: 186
    },
    {
        value: 'RINSPEED',
        label: 'RINSPEED',
        id: 187
    },
    {
        value: 'ROLLS-ROYCE',
        label: 'ROLLS-ROYCE',
        id: 188
    },
    {
        value: 'RONART',
        label: 'RONART',
        id: 189
    },
    {
        value: 'ROVER',
        label: 'ROVER',
        id: 190
    },
    {
        value: 'SAAB',
        label: 'SAAB',
        id: 191
    },
    {
        value: 'SAIPA',
        label: 'SAIPA',
        id: 192
    },
    {
        value: 'SALEEN',
        label: 'SALEEN',
        id: 193
    },
    {
        value: 'SANTANA',
        label: 'SANTANA',
        id: 194
    },
    {
        value: 'SATURN',
        label: 'SATURN',
        id: 195
    },
    {
        value: 'SCION',
        label: 'SCION',
        id: 196
    },
    {
        value: 'SEAT',
        label: 'SEAT',
        id: 197
    },
    {
        value: 'SHANGHAI MAPLE',
        label: 'SHANGHAI MAPLE',
        id: 198
    },
    {
        value: 'SHUANGHUAN',
        label: 'SHUANGHUAN',
        id: 199
    },
    {
        value: 'SIMCA',
        label: 'SIMCA',
        id: 200
    },
    {
        value: 'SKODA',
        label: 'SKODA',
        id: 201
    },
    {
        value: 'SMART',
        label: 'SMART',
        id: 202
    },
    {
        value: 'SOUEAST',
        label: 'SOUEAST',
        id: 203
    },
    {
        value: 'SPECTRE',
        label: 'SPECTRE',
        id: 204
    },
    {
        value: 'SPYKER',
        label: 'SPYKER',
        id: 205
    },
    {
        value: 'SSANGYONG',
        label: 'SSANGYONG',
        id: 206
    },
    {
        value: 'STEYR',
        label: 'STEYR',
        id: 207
    },
    {
        value: 'STUDEBAKER',
        label: 'STUDEBAKER',
        id: 208
    },
    {
        value: 'SUBARU',
        label: 'SUBARU',
        id: 209
    },
    {
        value: 'SUZUKI',
        label: 'SUZUKI',
        id: 210
    },
    {
        value: 'TALBOT',
        label: 'TALBOT',
        id: 211
    },
    {
        value: 'TATA',
        label: 'TATA',
        id: 212
    },
    {
        value: 'TATRA',
        label: 'TATRA',
        id: 213
    },
    {
        value: 'TAZZARI',
        label: 'TAZZARI',
        id: 214
    },
    {
        value: 'TESLA',
        label: 'TESLA',
        id: 215
    },
    {
        value: 'THINK',
        label: 'THINK',
        id: 216
    },
    {
        value: 'TIANMA',
        label: 'TIANMA',
        id: 217
    },
    {
        value: 'TIANYE',
        label: 'TIANYE',
        id: 218
    },
    {
        value: 'TOFAS',
        label: 'TOFAS',
        id: 219
    },
    {
        value: 'TOYOTA',
        label: 'TOYOTA',
        id: 220
    },
    {
        value: 'TRABANT',
        label: 'TRABANT',
        id: 221
    },
    {
        value: 'TRAMONTANA',
        label: 'TRAMONTANA',
        id: 222
    },
    {
        value: 'TRIUMPH',
        label: 'TRIUMPH',
        id: 223
    },
    {
        value: 'TVR',
        label: 'TVR',
        id: 224
    },
    {
        value: 'ULTIMA',
        label: 'ULTIMA',
        id: 225
    },
    {
        value: 'VAUXHALL',
        label: 'VAUXHALL',
        id: 226
    },
    {
        value: 'VECTOR',
        label: 'VECTOR',
        id: 227
    },
    {
        value: 'VENTURI',
        label: 'VENTURI',
        id: 228
    },
    {
        value: 'VOLKSWAGEN',
        label: 'VOLKSWAGEN',
        id: 229
    },
    {
        value: 'VOLVO',
        label: 'VOLVO',
        id: 230
    },
    {
        value: 'VORTEX',
        label: 'VORTEX',
        id: 231
    },
    {
        value: 'W MOTORS',
        label: 'W MOTORS',
        id: 232
    },
    {
        value: 'WANDERER',
        label: 'WANDERER',
        id: 233
    },
    {
        value: 'WARTBURG',
        label: 'WARTBURG',
        id: 234
    },
    {
        value: 'WESTFIELD',
        label: 'WESTFIELD',
        id: 235
    },
    {
        value: 'WIESMANN',
        label: 'WIESMANN',
        id: 236
    },
    {
        value: 'WILLYS',
        label: 'WILLYS',
        id: 237
    },
    {
        value: 'XIN KAI',
        label: 'XIN KAI',
        id: 238
    },
    {
        value: 'ZASTAVA',
        label: 'ZASTAVA',
        id: 239
    },
    {
        value: 'ZENOS',
        label: 'ZENOS',
        id: 240
    },
    {
        value: 'ZENVO',
        label: 'ZENVO',
        id: 241
    },
    {
        value: 'ZIBAR',
        label: 'ZIBAR',
        id: 242
    },
    {
        value: 'ZOTYE',
        label: 'ZOTYE',
        id: 243
    },
    {
        value: 'ZX',
        label: 'ZX',
        id: 244
    },
    {
        value: 'Автокам',
        label: 'Автокам',
        id: 245
    },
    {
        value: 'ГАЗ',
        label: 'ГАЗ',
        id: 246
    },
    {
        value: 'ЗАЗ',
        label: 'ЗАЗ',
        id: 247
    },
    {
        value: 'ЗИЛ',
        label: 'ЗИЛ',
        id: 248
    },
    {
        value: 'ЗиС',
        label: 'ЗиС',
        id: 249
    },
    {
        value: 'ИЖ',
        label: 'ИЖ',
        id: 250
    },
    {
        value: 'Канонир',
        label: 'Канонир',
        id: 251
    },
    {
        value: 'Комбат',
        label: 'Комбат',
        id: 252
    },
    {
        value: 'ЛуАЗ',
        label: 'ЛуАЗ',
        id: 253
    },
    {
        value: 'Москвич',
        label: 'Москвич',
        id: 254
    },
    {
        value: 'СМЗ',
        label: 'СМЗ',
        id: 255
    },
    {
        value: 'ТагАЗ',
        label: 'ТагАЗ',
        id: 256
    },
    {
        value: 'УАЗ',
        label: 'УАЗ',
        id: 257
    },
    {
        value: 'Ё-мобиль',
        label: 'Ё-мобиль',
        id: 258
    },
    {
        value: 'КАМАЗ',
        label: 'КАМАЗ',
        id: 259
    }
];
export default makes;
