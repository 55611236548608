import { jsx as _jsx } from "react/jsx-runtime";
import { getSelectValue } from '../../helpers';
import { ReactSelect } from './inputs';
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
function MultiSelect(_a) {
    var values = _a.values, onChange = _a.onChange, value = _a.value, errors = _a.errors, label = _a.label, name = _a.name, isCreatable = _a.isCreatable, canEdit = _a.canEdit;
    return (_jsx(ReactSelect, { onChange: onChange, defaultValue: value ? getSelectValue(value, values) : [], errors: errors, values: values, label: label, name: name, isCreatable: isCreatable, isMulti: true, canEdit: canEdit }));
}
////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
export default MultiSelect;
